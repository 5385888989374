import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index.vue'
import IndexHome from '@/views/index/index.vue'
import Login from '@/views/login/index.vue'
import store from '@/store'
import Cookies from "js-cookie";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'IndexHome',
    component: IndexHome
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/shopClassify',
    name: 'ShopClassify',
    component: () => import('@/views/shopClassify'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/shop'),
  },
  {
    path: '/journalism',
    name: 'Journalism',
    component: () => import('@/views/journalism'),
  },
  {
    path: '/culture',
    name: 'Culture',
    component: () => import('@/views/culture'),
  },
  {
    path: '/photo',
    name: 'Photo',
    component: () => import('@/views/photo'),
  },
  {
    path: '/storyOne',
    name: 'StoryOne',
    component: () => import('@/views/storyOne'),
  },
  {
    path: '/genealogy',
    name: 'genealogy',
    component: () => import('@/views/genealogy'),
  },
  {
    path: '/systemLog',
    name: 'systemLog',
    component: () => import('@/views/systemLog'),
  },
  {
    path: '/applyList',
    name: 'applyList',
    component: () => import('@/views/applyList'),
  },
  {
    path: '/genealogicallibrary',
    name: 'genealogicallibrary',
    component: () => import('@/views/genealogicallibrary'),
  },
  {
    path: '/reelNumber',
    name: 'reelNumber',
    component: () => import('@/views/reelNumber'),
  },
  {
    path: '/xinshi',
    name: 'xinshi',
    component: () => import('@/views/xinshi'),
  },
  {
    path: '/xinshiLanmu',
    name: 'xinshiLanmu',
    component: () => import('@/views/xinshi/xinshiLanmu.vue'),
  },
  {
    path: '/xinshiContent',
    name: 'xinshiContent',
    component: () => import('@/views/xinshi/xinshiContent.vue'),
  },
  {
    path: '/xinshiContentDetails',
    name: 'xinshiContentDetails',
    component: () => import('@/views/xinshi/xinshiContentDetails.vue'),
  },
  {
    path: '/auditList',
    name: 'auditList',
    component: () => import('@/views/auditList'),
  },
  {
    path: '/audiUsertList',
    name: 'audiUsertList',
    component: () => import('@/views/audiUsertList'),
  },
  {
    path: '/genealogyAllList',
    name: 'genealogyAllList',
    component: () => import('@/views/genealogyAllList'),
  },
  {
    path: '/shuntwindingList',
    name: 'shuntwindingList',
    component: () => import('@/views/shuntwindingList'),
  },
  {
    path: '/sublineList',
    name: 'sublineList',
    component: () => import('@/views/sublineList'),
  },
  {
    path: '/chartPicture',
    name: 'chartPicture',
    component: () => import('@/views/chartPicture'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 权限校验逻辑
  if(to.path=='/chartPicture'||to.path=='/login'){
    store.dispatch("setShowCont", false);
  }else{
    if(!Cookies.get("zujiUserInfo")){
      return next('/login');
    }
    store.dispatch("setShowCont", true);
  }
  next();
});

export default router
