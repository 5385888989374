import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showCont:false,
    userInfo:{}
  },
  getters: {
    showCont: state => state.showCont,
    userInfo: state => state.showCont,
  },
  mutations: {
    SETSHOWCONT(state,data) {
      state.showCont = data; 
    },
    SETUSERINFO(state,data) {
      state.userInfo = data; 
    },
  },
  actions: {
    setShowCont({ commit },data) {
      commit('SETSHOWCONT',data); // 调用 mutation
    },
    setUserInfo({ commit },data) {
      commit('SETUSERINFO',data); // 调用 mutation
    },
  },
  modules: {
  }
})
